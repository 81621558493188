<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<magente-modals
			:modelName="modelName"
		></magente-modals>
	</div>
</template>

<script>
import magenteActions from './MagenteActions';
import magenteModals from './MagenteModals.vue';

export default {
	name: 'magente-grid',
	components: {
		'magente-modals': magenteModals
	},
	data() {
		return {
			modelName: 'magente',
			actions: magenteActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
